import React from "react";
import PostItem from "../PostItem";
import Button from "../UI/Button";
import { PostListStyled, SectionStyled } from "./PostList.styled";

const PostList = ({ posts }) => {
  return (
    <SectionStyled className="section">
      <div className="container">
        <PostListStyled>
          {posts &&
            posts.map((post, index) => <PostItem post={post} key={index} />)}
        </PostListStyled>
        <Button appearance="secondary" text="Más posts" />
      </div>
    </SectionStyled>
  );
};

export default PostList;
