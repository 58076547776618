import styled from "styled-components";
import { mediaQuery } from "../../styles/mixins";

export const PostItemStyled = styled.li`
  border-bottom: 2px dotted #dee2e6;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  margin: 24px 0;
  padding: 24px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  h1 {
    font-size: 48px;  /* Larger than h2 */
    font-weight: 900;  /* Stronger emphasis */
    margin-bottom: 16px;  /* More space between elements */
  }
  
  h2 {
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 12px;
  }

  h3 {
    font-size: 32px;  /* Smaller than h2 */
    font-weight: 700;  /* Slightly less bold */
    margin-bottom: 10px;  /* Less space than h2 */
  }
  
  b, strong {
      font-weight: bold;  /* Default bold */
  }
  
  i, em {
      font-style: italic;  /* Default italic */
  }

  p {
    font-size: 1.25rem;
    line-height: 1.25;
    margin-bottom: 24px;
    text-align: justify;
  }

  /* Styles for unordered list */
  ul {
      list-style-type: disc;  /* Bullet points */
      padding-left: 20px;     /* Indent the list */
      margin-bottom: 24px;    /* Space below the list */
  }
  
  ul li {
      font-size: 1rem;         /* Font size for list items */
      line-height: 1.5;        /* Line height for readability */
      margin-bottom: 8px;      /* Space between items */
  }
  
  /* Styles for ordered list */
  ol {
      list-style-type: decimal;  /* Numbered list */
      padding-left: 20px;        /* Indent the list */
      margin-bottom: 24px;       /* Space below the list */
  }
  
  ol li {
      font-size: 1rem;            /* Font size for list items */
      line-height: 1.5;           /* Line height for readability */
      margin-bottom: 8px;         /* Space between items */
  }

  &:hover {
    cursor: pointer;
    color: var(--color__primary) !important;
  }

  ${mediaQuery.sm`
    flex-direction: row;
  `}
`;

export const ImageContainer = styled.div`
  /* border: solid 2px var(--color__primary); */
  width: 100%;
  height: 200px;
  margin-right: 24px;
  flex-shrink: 0;
  margin-bottom: 24px;
  overflow: hidden;
  ${mediaQuery.sm`
   width: 200px;
   margin-bottom: 0;
  `}
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Date = styled.p`
  font-style: italic;
  margin-top: auto;
  font-size: 16px !important;
`;
