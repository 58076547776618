import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import {
  PostItemStyled,
  ImageContainer,
  Information,
  Date,
} from "./PostItem.styled";
import Markdown from "markdown-to-jsx";

const PostItem = ({ post }) => {
  var maxLength = 100; // maximum number of characters to extract

  //trim the string to the maximum length
  var trimmedString = post.description.es.substr(0, maxLength);

  //re-trim if we are in the middle of a word
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );

  return (
    <Link to={post.slug.current}>
      <PostItemStyled>
        <ImageContainer>
          <Img fluid={post.image.asset.fluid} />
        </ImageContainer>
        <Information>
          {/* Information */}
          <h2>{post.title.es}</h2>
          <Markdown
            options={{
              overrides: {
                span: {
                  component: ({ children, ...props }) => (
                    <p {...props}>{children}</p>
                  ),         
                },
              },
            }}
          >{`${trimmedString}...`}</Markdown>
          <Date>Fecha: {post.date}</Date>
        </Information>
      </PostItemStyled>
    </Link>
  );
};

export default PostItem;
