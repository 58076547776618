import styled from "styled-components";
import { mediaQuery } from "../../styles/mixins";

export const PostListStyled = styled.ul``;

export const SectionStyled = styled.section`
  margin-bottom: 56px;
  ${mediaQuery.sm`
  margin-bottom: auto;
  `}
`;
